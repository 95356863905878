import { render, staticRenderFns } from "./pipe-gallery.vue?vue&type=template&id=5b4bea06&scoped=true"
import script from "./pipe-gallery.vue?vue&type=script&lang=js"
export * from "./pipe-gallery.vue?vue&type=script&lang=js"
import style0 from "./pipe-gallery.vue?vue&type=style&index=0&id=5b4bea06&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cm2j7qxmayqayl6phxhnflalsy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b4bea06",
  null
  
)

export default component.exports