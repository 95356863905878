import { render, staticRenderFns } from "./introduce-carousel.vue?vue&type=template&id=f4ed93fe&scoped=true"
import script from "./introduce-carousel.vue?vue&type=script&lang=js"
export * from "./introduce-carousel.vue?vue&type=script&lang=js"
import style0 from "./introduce-carousel.vue?vue&type=style&index=0&id=f4ed93fe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cm2j7qxmayqayl6phxhnflalsy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4ed93fe",
  null
  
)

export default component.exports